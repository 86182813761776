import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Avatar, Box, Card, Typography } from '@mui/material';
import { stringAvatar } from '../../../../utils/stringAvatar';
import { useLocales } from '../../../../locales';
export default function ProtheseUserInfo({ data }) {
    const { translate } = useLocales();
    return (_jsxs(Card, { sx: {
            padding: '15px 30px',
            display: 'flex',
            flexDirection: 'row',
            gap: '40px',
            width: '100%',
            justifyContent: 'start',
        }, children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'start', gap: '40px', justifyContent: 'center' }, children: [_jsx(Avatar, { src: data?.avatar, ...stringAvatar(`${data?.name}`) }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: data?.name }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.phone }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.gender })] })] }), _jsxs(Box, { children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: `${translate('patient_card_info')}` }), _jsx(Typography, { variant: "body1", color: 'text.secondary', children: data?.description || '_' })] })] }));
}
